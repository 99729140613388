import React, { useState } from 'react';
import { Drawer, Button, Select, MenuItem, TextField, FormControl, InputLabel, Box, IconButton, Tab, Tabs, Typography, type Theme, useMediaQuery, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ApprovalActions from './approvalActions';
import { ApprovalChain } from 'modules/cbre-approval-chain-widget';
import { ApprovalsHistory } from 'modules/cbre-approval-history';
import ApprovalDetailsView from './ApprovalDetails';
import { useApprovalsContext } from 'modules/cbre-approvals-widget';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "../styles/approvalRequestDetails.scss";
import ProgressStepper from './requestProgress';
import { approvalTypes } from 'modules/cbre-approvals-widget/components/utils';

type RequestDetailsViewDrawerProps = {
    setIsOpen: (open: boolean) => void
    requestId?: string | null
    apiData?: any
    isMyApproval: boolean;
};

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1, height: "100%" }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const TabContainer: React.FC<any> = ({ approvalInfo, isTabRequired = true }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className="approvals-request-bar">
            <Tabs value={value} onChange={handleChange} aria-label="approval tabs" sx={{ minHeight: "auto", display: isTabRequired ? "flex" : "none" }} >
                <Tab className='approval-request-container-tab-item' label="Request" />
                <Tab className='approval-request-container-tab-item' label="Approval Chain" />
                <Tab className='approval-request-container-tab-item' label="Approval History" />
            </Tabs>
            <TabPanel value={value} index={0} className="approval-request-detail-tab-panel">
                <ApprovalDetailsView apiData={approvalInfo} mode="large" />
            </TabPanel>
            <TabPanel value={value} index={1} className="approval-request-detail-tab-panel">
                <ApprovalChain approvals={8} totalApprovals={12} />
            </TabPanel>
            <TabPanel value={value} index={2} className="approval-request-detail-tab-panel">
                <ApprovalsHistory approvals={approvalInfo} />
            </TabPanel>
        </div>
    );
};

const RequestDetailsView: React.FC<RequestDetailsViewDrawerProps> = ({ setIsOpen, apiData, isMyApproval }) => {

    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const { approvalDetails, cdfDetails } = apiData || {};

    const additionalDetails = cdfDetails?.fieldSchema ? cdfDetails.fieldSchema[0] || {} : {}

    const currentApprovalType = "pendingApproval"

    const isTabRequired = true;

    const requestData = apiData?.approvalDetails || {};

    return (
        <div>

            {approvalDetails && <Box
                sx={{ padding: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
                role="presentation"
                className="approval-request-details-container"
            >
                <div className='d-flex approval-request-header'>
                    <div className='d-flex align-items-baseline'>
                        {!isDesktop && <IconButton onClick={() => { setIsOpen(false) }}>
                            <ArrowBackIcon />
                        </IconButton>}
                        <Typography variant="h5" className='mr-2'>{additionalDetails.heading}</Typography>

                        {approvalTypes[currentApprovalType] && <div className='d-flex align-items-center'>
                            {approvalTypes[currentApprovalType].icon}
                            {<Typography variant="body2" className='ml-1'>{approvalTypes[currentApprovalType].label}</Typography>}
                        </div>}          </div>
                    <div className='d-flex align-items-center'>
                        { isMyApproval && <ApprovalActions />}
                        {isDesktop && <IconButton onClick={() => { setIsOpen(false) }}>
                            <CloseIcon />
                        </IconButton>}

                    </div>
                </div>
                {isDesktop ? <div className='desktop-request-detail-container'>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <Box >
                                <TabContainer approvalInfo={apiData} isTabRequired={isTabRequired} />

                            </Box>
                        </Grid>
                        <Grid item xs={3} sx={{ marginTop: isTabRequired ? 7 : 2, backgroundColor: "#F5F7F7", paddingRight: 2 }} >
                            <Box style={{ height: "100%", paddingBottom: 16 }}>
                                <ProgressStepper 
                                    status={(requestData as {workrequest_timeline_status: string})?.workrequest_timeline_status}
                                    workRequestStatus={(requestData as {status: string})?.status}
                                    subStatus={(requestData as {c_worksubstatus: string})?.c_worksubstatus}
                                    requestStatus={(requestData as {c_workstatus: string})?.c_workstatus}
                                    combinedStatus={(requestData as {combined_work_status: string})?.combined_work_status}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </div> : <TabContainer approvalInfo={apiData} />}

            </Box>}
        </div>
    );
};

export default RequestDetailsView;