import React, { useEffect, useState } from 'react';
import HistoryItem from './historyItem';
import '../styles/ApprovalsBar.scss';
import { useApprovalsContext } from 'modules/cbre-approvals-widget';
import service from 'util/apiService';
import { ApprovalAuditHistory } from '../types';
import moment from 'moment';

interface IhistoryItem {
    status: string;
    date: string;
    author: string;
    role: string;
    email: string;
    comments?: string;  
}

interface ApprovalHistoryProps {
    approvals?: number;
    totalApprovals?: number;
  }

const ApprovalsHistory: React.FC<ApprovalHistoryProps> = ({ approvals }) => {
  const [historyItems, setHistoryItems] = useState<ApprovalAuditHistory[]>([])

  const { config } = useApprovalsContext()!

  useEffect(()=>{

    service.get(`${config.baseUrl}/approval-audit-history`).then((response) => {
      setHistoryItems(response.data?.recordsList || [])
    }).catch((error) => {
      console.error('Error fetching approval history', error)
    })
    // const historyItems:IhistoryItem[] = [
    //   {
    //     status: 'Approved',
    //     date: '9/26/24 9:35 PM',
    //     author: 'Barney Rubble',
    //     role: 'Manager',
    //     email: '',
    //     comments: 'Approved'
    //   },
    //   {
    //     status: 'Approved',
    //     date: '9/27/24 9:35 PM',
    //     author: 'Wilma Flintstone',
    //     role: 'Manager',
    //     email: '',
    //     comments: 'Approved'
    //   },
    //   {
    //     status: 'Approved',
    //     date: '9/27/24 9:35 PM',
    //     author: 'Wilma Flintstone',
    //     role: 'Manager',
    //     email: '',
    //     comments: 'Approved'
    //   },
    //   {
    //     status: 'Pending Approval',
    //     date: '9/27/24 9:35 PM',
    //     author: 'Joe Rockhead',
    //     role: 'Manager',
    //     email: '',
    //     comments: ''
    //   }
    // ]
      // setHistoryItems(historyItems)
  }, [])

  const statusMap:any = {
    FLOW_PENDING: "Pending",
    FLOW_APPROVED: "Approved",
    FLOW_REJECTED: "Rejected",
  }


  return (
    <div className="approval-history-container">
      <div className="history-wrap">
        {historyItems.length !== 0 && historyItems.map((item, index) => 
          {
            const info = item.events?.[0] || {}
            const status = statusMap[info.approvalFlowStatus?.uniqueCode] || ''
            const date = info.createdAt ? moment(info.createdAt).format("MM/DD/YY hh:mm A") : ''
            const author = info.approver?.firstName + ' ' + info.approver?.lastName;
            const email = info.approver?.email
            const roleInfo = '';
            const comments = ''
            return <HistoryItem key={index} {...{status, author, date, email, roleInfo, comments}} />}
        )}
      </div>
    </div>
  );
};

export default ApprovalsHistory;