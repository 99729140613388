
/* eslint-disable */
import React from 'react';
import IntlMessages from "../../util/IntlMessages"
import {makeStyles} from "@mui/styles";
import './SearchBox.scss';
import TextBox from 'components/TextBox';
import { ButtonBase } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    input: {
        font: 'inherit',
        letterSpacing: 'inherit',
        border: 0,
        padding: '10px 35px 10px 14px',
        background: 'none',
        margin: 0,
        display: 'block',
        minWidth: '300px',
        width: '100%',
    }
}));

const SearchBox = ({
  styleName, onChange, value, clearSearch, onKeyDown, disabled, label, InputProps = {}
}) => {
    const classes = useStyles();
    return (
        <div className={`search-bar right-side-icon bg-transparent ${styleName}`}>
            <div className="form-group">
                <TextBox
                    className="form-control m-0"
                    InputProps={{
                        inputProps: {
                            className: classes.input,
                        },
                        ...InputProps
                    }}
                    label={label}
                    defaultValue={value || ''}
                    value={value}
                    variant="outlined"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    disabled={disabled}
                />
                {value
                    ? <ButtonBase component="button" focusRipple className="search-icon close-icon project-list-search" onClick={clearSearch}><i className="zmdi zmdi-close zmdi-hc-lg" /></ButtonBase>
                    : <ButtonBase component="button" focusRipple className="search-icon project-list-search"><i className="zmdi zmdi-search zmdi-hc-lg" /></ButtonBase>}
            </div>
        </div>
    );
}
export default SearchBox;

SearchBox.defaultProps = {
  styleName: '',
  value: '',
};
