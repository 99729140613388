import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import { ApprovalTypes, IApprovalTab } from '../types';

export const tabs: IApprovalTab["tab"][] = [
    { label: 'My Approval Needed', value: 'myApprovals', icon: <CircleIcon style={{ color: "#FF543E", fontSize: 14 }} />  },
    { label: 'External Approval Needed', value: 'externalApprovals', icon: <CircleOutlinedIcon style={{ color: "#8585E0", fontSize: 14 }} /> },
    { label: 'My Approval History', value: 'approvalsHistory', icon: <CircleIcon style={{ color: "#959595", fontSize: 14 }} />  },
];

export const approvalTypes:ApprovalTypes = {
    pendingApproval: { label: "Pending Approval", icon: <CircleOutlinedIcon style={{ color: "#8585E0", fontSize: 14 }} />  },
    approved: { label: "Approved", icon: <CircleIcon style={{ color: "#959595", fontSize: 14 }} />   },
    rejected: { label: "Rejected", icon: <CircleIcon style={{ color: "#959595", fontSize: 14 }} />   },
}