import * as React from 'react';
import { IconButton, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useApprovalsContext } from 'modules/cbre-approvals-widget';

const options = ['Due Date', 'Approval Category', 'Requestor'];

export default function ApprovalSortByBtn() {
  const { tabValue, config } = useApprovalsContext()!;
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const sortOrder = config.approvalsList.params.order?.toLowerCase() || 'asc'

  const handleSort = () => {
    const newSortOrder: 'asc' | 'desc' = sortOrder === 'asc' ? 'desc' : 'asc';

    config.approvalsList.setQueryParams((prev) => {
      const updatedParams = { ...prev, order: newSortOrder?.toUpperCase() as any };
      return updatedParams;
  });
    config.approvalsList.handlePendingChanges().then((config) => {
        config.fetchApprovals()
    })

  };

  const approvalLength  = config.approvalsList.approvals?.length || 0


  return (
    <div className='d-flex align-items-center justify-content-between'>
      <Typography> { approvalLength } Approvals </Typography>

      <Typography> Sort by: {options[selectedIndex]}
        <IconButton
          size="small"
          className='sort-by-approval-btn-asc-desc border-none'
          onClick={handleSort}
        >
          {sortOrder === 'asc' ? <ArrowUpwardIcon sx={{width: 18, height: 18}}/> : <ArrowDownwardIcon sx={{width: 18, height: 18}}/>}
        </IconButton>
      </Typography>
    </div>
  );
}